import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { css } from '@emotion/core'
import { ThemeProvider } from 'emotion-theming'
import theme from '../utils/theme'
import Header from './header'
import Container from '../components/container'
import Footer from '../components/footer'

const Layout = ({ location, children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <ThemeProvider theme={theme}>
        <div
          css={css`
            display: flex;
            flex-flow: column nowrap;
            height: 100vh;
          `}
        >
          <Header siteTitle={data.site.siteMetadata.title} />
          <Container css={{ paddingTop: '8rem' }}>
            <main>{children}</main>
          </Container>
          <Footer />
        </div>
      </ThemeProvider>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
