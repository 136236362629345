import React from 'react'
import Layout from '../components/layout'
import styled from '@emotion/styled'
import mq from '../utils/media-queries'
import theme from '../utils/theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const PostWrap = styled.main`
  margin: 0 auto;
  padding: 8rem 0;
  max-width: 880px;
`
const PostHero = styled.div`
  background: ${theme.black};
  color: ${theme.white};
  position: relative;
  z-index: 1;
  padding: 1rem;
  h1 {
    font-size: 2rem;
    line-height: 1.5;
    margin: 0.5rem;
  }
  ${mq[1]} {
    font-size: 1.5rem;
  }
  ${mq[2]} {
    text-align: center;
    margin: 1rem 0;
  }
`
const PostHeroBG = styled.div`
  position: absolute;
  width: 100%;
  height: 195px;
  left: 0px;
  top: 0px;
  background: linear-gradient(
      191.57deg,
      rgba(255, 255, 255, 0) 15.29%,
      rgba(39, 39, 39, 0.3) 87.19%
    ),
    #1dd39d;
  background-blend-mode: darken, normal;
`
const PostShare = styled.div`
  color: ${theme.black};
  font-size: 1rem;
  display: flex;
  justify-content: center;
  margin: 0 0 28px 0;
  padding: 0.5rem 0;
  a {
    margin: 0 0.5rem;
    cursor: pointer;
    transition: color 0.15s;
    :hover {
      color: ${theme.primary};
    }
  }
`
const PostContent = styled.div`
  font-size: 14px;
  max-width: 720px;
  margin: 0 auto;
  a {
    color: ${theme.white};
    background: ${theme.black};
    padding: 0.2rem 0.4rem;
    :hover {
      color: ${theme.black};
      background: ${theme.primary};
    }
  }
  h3 {
    margin-top: 3rem;
  }
  ${mq[2]} {
    font-size: 1rem;
  }
`
const ShareButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  transition: all .2s;
  &:hover {
    color: ${theme.primary}
  }
`

const CopyInput = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1;
`

// TODO: add hashtags and such to tweet window
function tweetPost() {
  const tweetWindowFeatures = `
    outerWidth=600,
    outerHeight=420,
    centerscreen=yes,
    chrome=yes"
  `
  window.open(
    `https://twitter.com/intent/tweet?text=Check%20this%20out! - ${window.location}`,
    'Tweet',
    tweetWindowFeatures
  )
}
function copyLink() {
  const input = document.querySelector('#copyInput');
  input.value = window.location;
  input.select();
  document.execCommand("copy");
  alert('Link copied! ' + input.value);
}

const Post = ({ pageContext: { title, date, content } }) => (
  <Layout navHover="white">
    <PostWrap>
      <PostHeroBG />
      <PostHero>
        {/* <small>{date}</small> */}
        <h1>{title}</h1>
      </PostHero>
      <PostShare>
        <ShareButton onClick={tweetPost}>
          <FontAwesomeIcon icon={['fab', 'twitter']} />
        </ShareButton>
        <ShareButton onClick={copyLink}>
          <FontAwesomeIcon icon="link" />
          <CopyInput id="copyInput" type="text" />
        </ShareButton>
      </PostShare>
      <PostContent dangerouslySetInnerHTML={{ __html: content }} />
    </PostWrap>
  </Layout>
)
export default Post
